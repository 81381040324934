import React, { useContext } from 'react';
import { Col } from 'reactstrap';
import { TextField } from '@material-ui/core';
import { T } from '../../utils/translate/translator';
import { AutofocusContext } from '../../context/AutofocusContext';

import BottomNavigation from '../common/BottomNavigation';

interface ScanTrolleyProps {
    trolleyId: string,
    deactivateListener: (active: boolean) => void,
    setTrolleyId: (trolley: string) => void,
    handleNext: () => void
}

export default function ScanTrolley(props: ScanTrolleyProps) {
    const focus = useContext(AutofocusContext);

    return (
        <Col className="text-center mt-5">
            <TextField variant="outlined" label={T("ScanTrolley")} placeholder={T("ScanBarcode")} autoFocus={focus.autofocus}
                value={props.trolleyId} onChange={(e) => props.setTrolleyId(e.target.value.toUpperCase())}
                onKeyDown={() => props.deactivateListener(true)} onBlur={() => props.deactivateListener(false)} />

            <BottomNavigation backDisabled handleNext={props.handleNext} disableNext={!props.trolleyId} />
        </Col>
    );
}