export const cs = new Map<string, string>();

//Layout
cs.set("Logout", "Odhlásit se");
cs.set("Language", "Jazyk");
cs.set("DarkMode", "Tmavý režim");
cs.set("AreYouSureYouWantToContinue", "Opravdu chcete pokračovat?");
cs.set("Photo", "Fotka");
cs.set("Settings", "Nastavení");
cs.set("DockMenu", "Ukotvit menu");
cs.set("Name", "Jméno");
cs.set("EmployeeId", "ID zaměstnance");
cs.set("Department", "Oddělení");
cs.set("SignIn", "Přihlásit se");
cs.set("Home", "Domů");
cs.set("Battery", "Baterie");

cs.set("Replenishment", "Doplnění materiálu");
cs.set("Accessory", "Příslušenství");
cs.set("TrolleyInfo", "Informace k vozíku");

cs.set("ScanBarcode", "Naskenuj čárový kód");
cs.set("ScanBin", "Naskenuj BIN");
cs.set("ScanCpn", "Naskenuj CPN");
cs.set("ScanPN", "Naskenuj P/N");
cs.set("ScanQty", "Naskenuj množství");
cs.set("Back", "Zpět");
cs.set("Next", "Další");
cs.set("Finish", "Dokončit");
cs.set("ScanTrolley", "Naskenuj vozík");
cs.set("Trolley", "Vozík");
cs.set("Position", "Pozice");
cs.set("BinQuantity", "Celkem");
cs.set("PickQuantity", "Vzít");
cs.set("Quantity", "Množství");
cs.set("Current", "Aktuální");
cs.set("Scanned", "Naskenováno");

//Upozornění
cs.set("BinError1/2", "Naskenovaný BIN (");
cs.set("BinError2/2", ") není shodný");
cs.set("ScanBinHelperText", "Jdi k regálu a naskenuj BIN location");
cs.set("PositionError1/2", "Naskenovaná pozice (");
cs.set("PositionError2/2", ") je špatná");
cs.set("SfcsDeductError", "Chyba při odečtu množství ze SFCS:");
cs.set("NoTrolleyOpen", "Není otevřený žádný vozík");
cs.set("NotTheRightTrolley", "Tohle není správný vozík");