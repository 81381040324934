import React, { useContext } from 'react';
import { ButtonGroup, Button } from '@material-ui/core';
import { Col } from 'reactstrap';
import { T } from '../../utils/translate/translator';
import { LayoutContext } from '../../context/LayoutContext';

interface BottomNavigationProps {
    handleNext: () => void,
    handleBack?: () => void,
    disableNext?: boolean,
    backDisabled?: boolean
    finish?: boolean
}

export default function BottomNavigation(props: BottomNavigationProps) {
    const layout = useContext(LayoutContext);

    return (
        <Col xs={8} md={6} lg={4} className="mx-auto mt-4">
            <ButtonGroup fullWidth>
                <Button variant="outlined" onClick={props.handleBack} disabled={props.backDisabled || layout.isLoading}>{T("Back")}</Button>
                <Button variant="contained" onClick={props.handleNext} color="primary" disabled={props.disableNext || layout.isLoading}>{props.finish ? T("Finish") : T("Next")}</Button>
            </ButtonGroup>
        </Col>
    );
}