import React, { useContext, useEffect, useState } from 'react';
import { T } from '../utils/translate/translator';
import { allowedChars } from '../utils/allowedChars';
import moment from 'moment';
import EventListener from 'react-event-listener';

import { UserContext } from '../context/UserContext';
import { LayoutContext } from '../context/LayoutContext';

//Components
import ScanUsn from '../components/common/ScanUsn';
import BinInformation from '../components/common/BinInformation';

//Models and enums
import { SapDayNumbers } from '../models/enums/SapDayNumbers';
import { ApiFunctions } from '../models/enums/ApiFunctions';
import { PickingInfo, InfoModel } from '../models/PickingInfo';
import PickingInfoRequest from '../models/requests/PickingInfoRequest';
import DeductMaterialRequest from '../models/requests/DeductMaterialRequest';

import { apiService } from '../utils/ApiService';

export default function D6Picking() {
    const [activeStep, setActiveStep] = useState(0);
    const [deactivateListener, setDeactivateListener] = useState(false);
    const [usn, setUsn] = useState("");
    const [bin, setBin] = useState("");
    const [data, setData] = useState([] as PickingInfo[]);
    const [dataIndex, setDataIndex] = useState(0);
    const layout = useContext(LayoutContext);
    const user = useContext(UserContext);

    useEffect(() => {
        layout.setTitle("D6 Picking (High Value)");

        // eslint-disable-next-line
    });

    const keyboardListener = (e: KeyboardEvent) => {
        if (layout.isLoading)
            return;

        if (e.key.match(allowedChars) && !deactivateListener) {
            if (activeStep === 0)
                return setUsn((prevState) => prevState + e.key.toUpperCase());

            if (activeStep === 1)
                return setBin((prevState) => prevState + e.key.toUpperCase());
        }

        //Akce, které se budou v různých krocích provádět na Enter
        if (e.key === 'Enter') {
            if (activeStep === 0 && usn)
                return loadData();
            else if (activeStep === 1 && bin)
                return verifyBin();
        }
    }

    //Handlers
    const handleNext = () => { setActiveStep(activeStep + 1); setDeactivateListener(false) };
    const handleBack = () => { setActiveStep(activeStep - 1); setDeactivateListener(false) };
    const handleReset = () => {
        setActiveStep(0);
        setDeactivateListener(false);
        layout.success("OK");
        setBin("");
        setUsn("");
        setData([] as PickingInfo[]);
        setDataIndex(0);
        layout.setIsLoading(false);
    }

    const loadData = () => {
        layout.setIsLoading(true);

        const requestData: PickingInfoRequest = {
            REQID: moment().format('YYYYMMDDHHmmssSSSSSS'),
            USERID: user.user.employeeId,
            TROLLEY: "",
            POSITION: "",
            USN: usn,
            DAYN: SapDayNumbers.Day6
        }

        apiService.post("", ApiFunctions.GetPickingData, requestData, {
            success: (data: PickingInfo[]) => {
                layout.setIsLoading(false);
                setData(data);
                handleNext();
            },
            error: (message: string) => {
                layout.error(message);
                layout.setIsLoading(false);
                setUsn("");
            }
        });
    }

    const verifyBin = () => {
        if (bin === data[dataIndex].BIN)
            deductMaterial();
        else {
            layout.error(T("BinError1/2") + bin + T("BinError2/2"));
            setBin("");
        }
    }

    const deductMaterial = () => {
        let item: InfoModel | undefined = data[dataIndex].INFO.find(i => i.USN.toUpperCase() === usn.toUpperCase());

        if (item && !item.isScanned) {
            layout.setIsLoading(true);

            const deductRequest: DeductMaterialRequest = {
                REQID: moment().format('YYYYMMDDHHmmssSSSSSS'),
                USERID: user.user.employeeId,
                TROLLEY: "",
                USN: usn,
                POSITION: "",
                DAYN: SapDayNumbers.Day6,
                BIN: bin,
                QTY: item.QTY
            }

            apiService.post("", ApiFunctions.DeductMaterial, deductRequest, {
                success: () => {
                    layout.setIsLoading(false);
                    nextBinOrFinish();
                },
                error: (message: string) => {
                    layout.error(T("SfcsDeductError") + " " + message);
                    layout.setIsLoading(false);
                }
            });
        } else {
            layout.error("USN not found");
        }
    }

    const nextBinOrFinish = () => {
        if (data.length - 1 === dataIndex)
            handleReset();
        else {
            setDataIndex(dataIndex + 1);
            setBin("");
        }
    }

    return (
        <EventListener target="document" onKeyUp={keyboardListener}>
            {activeStep === 0 &&
                <ScanUsn usn={usn} setUsn={setUsn} deactivateListener={setDeactivateListener} handleNext={loadData} />
            }

            {activeStep === 1 &&
                <BinInformation data={data[dataIndex]} bin={bin} setBin={setBin} isFinish={data.length - 1 === dataIndex} deactivateListener={setDeactivateListener} handleNext={verifyBin} handleBack={handleBack} showPickQty>
                    <b>USN: </b>{usn}
                </BinInformation>
            }
        </EventListener>
    );
}