import moment from 'moment';
import { trimEnd } from 'lodash';

/** Returns true if the platform is "Win32". */
export const isDesktop: boolean = navigator.platform === "Win32";

/** Returns true if the project is running localy, or is deployed to an address containing ".dev". */
export const isDevelopment: boolean = window.location.href.includes(".dev") || window.location.href.includes("localhost") || process.env.NODE_ENV === 'development';

/** Returns true if it's your department, or you manage it. */
export const isMyOrSubDepartment = (myDepartment: string, department: string): boolean => {
    const trimmedMyDepartment = trimEnd(myDepartment, "0");
    const trimmedDepartment = trimEnd(department, "0");

    if (trimmedDepartment.includes(trimmedMyDepartment)) {
        return true
    } else {
        return false
    }
}

/** Use to convert Timestamp to DateTime. */
export const formatDate = (time: string, disableTime?: boolean) => {
    if (disableTime)
        return moment(time).format('YYYY-MM-DD');
    else
        return moment(time).format('YYYY-MM-DD HH:mm');
}

/** Use to vibrate (only on mobile). */
export const vibrate = (pattern: number | number[] = 200) => {
    window.navigator.vibrate(pattern);
}