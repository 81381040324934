import React, { useState, createContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import packageJson from '../../package.json';
import { Snackbar, LinearProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import Layout from '../components/layout/Layout';
import { AlertSeverity } from '../models/enums/AlertSeverity';

export const LayoutContext = createContext({
    /** Sets the title of the AppBar */
    setTitle: (title: string) => { },
    success: (message: string) => { },
    error: (message: string) => { },
    warning: (message: string) => { },
    info: (message: string) => { },
    setIsLoading: (isLoading: boolean) => { },
    isLoading: false,
    /**
    *Instead of the left menu, use the back arrow on the page (write into useEffect)
    *Not applicable when the menu is docked
    */
    useBackArrow: (pathToRedirect: string) => { }
})

export function LayoutProvider(props: { children?: React.ReactNode }) {
    const [title, setTitle] = useState(packageJson.name);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertSeverity, setAlertSeverity] = useState(AlertSeverity.SUCCESS);
    const [isLoading, setIsLoading] = useState(false);
    const [useBackArrow, setUseBackArrow] = useState("");
    const history = useHistory();

    useEffect(() => {
        if (useBackArrow)
            history.listen(() => setUseBackArrow(""));

        // eslint-disable-next-line
    }, [useBackArrow]);

    useEffect(() => {
        if (isLoading)
            setTimeout(() => setIsLoading(false), 6000);

        // eslint-disable-next-line
    }, [isLoading]);

    const success = (message: string) => { setAlertSeverity(AlertSeverity.SUCCESS); setAlertMessage(message) }
    const error = (message: string) => { setAlertSeverity(AlertSeverity.ERROR); setAlertMessage(message) }
    const warning = (message: string) => { setAlertSeverity(AlertSeverity.WARNING); setAlertMessage(message) }
    const info = (message: string) => { setAlertSeverity(AlertSeverity.INFO); setAlertMessage(message) }

    return <LayoutContext.Provider value={{
        setTitle: setTitle, setIsLoading: setIsLoading, isLoading: isLoading, useBackArrow: setUseBackArrow,
        success: success, error: error, warning: warning, info: info
    }}>
        <Layout title={title} useBackArrow={useBackArrow} setUseBackArrow={setUseBackArrow}>
            {props.children}

            <Snackbar open={Boolean(alertMessage)} onClose={() => setAlertMessage("")} autoHideDuration={6000} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
                <Alert onClose={() => setAlertMessage("")} severity={alertSeverity} variant="filled">{alertMessage}</Alert>
            </Snackbar>

            <LinearProgress className="fixed-bottom position-fixed" hidden={!isLoading} />
        </Layout>
    </LayoutContext.Provider>
}