export enum ApiFunctions{
    AddBinInfo = "1",
    GetTrolleyInfo = "2",
    LinkTrolleyInfo = "3",
    UpdateTrolleyStatus = "4",
    GetPickingData = "5",
    DeductMaterial = "6",
    GetBinInfo = "7",
    GetLocationInfo = "8",
    UniPicking = "9"
}