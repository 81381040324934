import React, { useContext, useEffect, useState } from 'react';
import { Stepper, Step, StepLabel, Button, TextField, OutlinedInput, InputAdornment, IconButton, Chip, Typography } from '@material-ui/core';
import { Add, Replay } from '@material-ui/icons';
import { Col } from 'reactstrap';
import { T } from '../utils/translate/translator';
import { sum } from 'lodash';
import moment from 'moment';
import EventListener from 'react-event-listener';

import { allowedChars, allowedNumbers } from '../utils/allowedChars';

import { UserContext } from '../context/UserContext';
import { AutofocusContext } from '../context/AutofocusContext';
import { LayoutContext } from '../context/LayoutContext';

//Models and enums
import { ApiFunctions } from '../models/enums/ApiFunctions';
import { BinLocation } from '../models/BinLocation';
import { BinInfo } from '../models/BinInfo';
import BinInfoRequest from '../models/requests/BinInfoRequest';

import { apiService } from '../utils/ApiService';

export default function ReplenishmentV2() {
    const [activeStep, setActiveStep] = useState(0);
    const [deactivateListener, setDeactivateListener] = useState(false);
    const [bin, setBin] = useState("");
    const [cpn, setCpn] = useState("");
    const [quantity, setQuantity] = useState("");
    const [currentQuantity, setCurrentQuantity] = useState(0);
    const [totalQuantity, setTotalQuantity] = useState([] as number[]);
    const [binLocations, setBinLocations] = useState([] as BinLocation[]);
    const layout = useContext(LayoutContext);
    const user = useContext(UserContext);
    const focus = useContext(AutofocusContext);

    useEffect(() => {
        layout.setTitle(T("Replenishment") + " v2");

        // eslint-disable-next-line
    });

    const keyboardListener = (e: KeyboardEvent) => {
        if (layout.isLoading)
            return;

        if (e.key.match(allowedChars) && !deactivateListener) {
            if (activeStep === 0)
                return setCpn((prevState) => prevState + e.key.toUpperCase());

            if (activeStep === 1)
                return setBin((prevState) => prevState + e.key.toUpperCase());

            if (activeStep === 2) {
                if (e.key.match(allowedNumbers))
                    return setQuantity((prevState) => prevState + e.key);
            }
        }

        //Akce, které se budou v různých krocích provádět na Enter
        if (e.key === 'Enter') {
            if (activeStep === 0 && cpn)
                return getCpnLocations();
            else if (activeStep === 1 && bin)
                return getBinInfo();
            else if (activeStep === 2)
                return addToQuantity();
        }
    }

    const getSteps = () => {
        return [
            cpn ? 'CPN: ' + cpn : T("ScanCpn"),
            bin ? 'BIN: ' + bin : T("ScanBin"),
            activeStep === 2 ?
                <span>
                    <div>{T("Current")}: {currentQuantity}</div>
                    <div>{T("Scanned")}: {sum(totalQuantity)}</div>
                </span>
                :
                T("Quantity")
        ];
    }

    //Handlers
    const handleNext = () => { setActiveStep(activeStep + 1); setDeactivateListener(false) };
    const handleBack = () => { setActiveStep(activeStep - 1); setDeactivateListener(false) };
    const handleReset = (message: string) => {
        setActiveStep(0);
        layout.success(message);
        setDeactivateListener(false);
        setBin("");
        setCpn("");
        setQuantity("");
        setTotalQuantity([] as number[]);
        layout.setIsLoading(false);
        setBinLocations([] as BinLocation[]);
    };

    const addToQuantity = (byClick?: boolean) => {
        totalQuantity.push(Number(quantity))
        setQuantity("");

        if (byClick)
            (document.getElementById("Quantity") as HTMLInputElement).focus();
    }

    const removeFromQuantity = () => {
        totalQuantity.pop();
        (document.getElementById("Quantity") as HTMLInputElement).focus();
    }

    const getCpnLocations = () => {
        layout.setIsLoading(true);

        const requestData = {
            REQID: moment().format('YYYYMMDDHHmmssSSSSSS'),
            USERID: user.user.employeeId,
            PARTNUMBER: cpn
        }

        apiService.post("", ApiFunctions.GetLocationInfo, requestData, {
            success: (data) => {
                layout.setIsLoading(false);
                setBinLocations(data);
                handleNext();
            },
            error: (message: string) => {
                layout.error(message);
                layout.setIsLoading(false);
                setCpn("");
            }
        });
    }

    const getBinInfo = () => {
        layout.setIsLoading(true);

        const requestData = {
            REQID: moment().format('YYYYMMDDHHmmssSSSSSS'),
            USERID: user.user.employeeId,
            BIN: bin
        }

        apiService.post("", ApiFunctions.GetBinInfo, requestData, {
            success: (data: BinInfo) => {
                layout.setIsLoading(false);
                setCurrentQuantity(data.CurrentQTY);
                handleNext();
            },
            error: (message: string) => {
                layout.error(message);
                layout.setIsLoading(false);
                setBin("");
            }
        });
    }

    const submit = () => {
        layout.setIsLoading(true);

        const requestData: BinInfoRequest = {
            BIN: bin,
            PARTNUMBER: cpn,
            QTY: sum(totalQuantity),
            REQID: moment().format('YYYYMMDDHHmmssSSSSSS'),
            USERID: user.user.employeeId
        }

        apiService.post("", ApiFunctions.AddBinInfo, requestData, {
            success: () => {
                handleReset("OK: " + T("Quantity") + " = " + (sum(totalQuantity) + currentQuantity));
            },
            error: (message: string) => {
                layout.setIsLoading(false);
                layout.error(message);
                (document.getElementById("Quantity") as HTMLInputElement).focus();
            }
        });
    }

    return (
        <EventListener target="document" onKeyUp={keyboardListener}>
            <Stepper activeStep={activeStep} alternativeLabel className="bg-transparent">
                {getSteps().map((label, index) => (
                    <Step key={index}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>

            {activeStep === 0 &&
                <React.Fragment>
                    <Col className="d-flex justify-content-center">
                        <TextField variant="outlined" placeholder={T("ScanCpn")} className="mb-2" value={cpn} autoFocus={focus.autofocus}
                            onChange={(e) => setCpn(e.target.value.toUpperCase())} onKeyDown={() => setDeactivateListener(true)} onBlur={() => setDeactivateListener(false)} />
                    </Col>

                    <Col className="d-flex justify-content-center">
                        <Button disabled={true} onClick={handleBack}>{T("Back")}</Button>
                        <Button variant="contained" color="primary" onClick={() => getCpnLocations()} disabled={cpn.length === 0 || layout.isLoading}>{T("Next")}</Button>
                    </Col>
                </React.Fragment>
            }

            {activeStep === 1 &&
                <React.Fragment>
                    <Col className="d-flex justify-content-center">
                        <TextField variant="outlined" placeholder={T("ScanBin")} className="mb-2" value={bin} autoFocus={focus.autofocus}
                            onChange={(e) => setBin(e.target.value.toUpperCase())} onKeyDown={() => setDeactivateListener(true)} onBlur={() => setDeactivateListener(false)} />
                    </Col>

                    <Col className="d-flex justify-content-center">
                        <Button onClick={handleBack}>{T("Back")}</Button>
                        <Button variant="contained" color="primary" onClick={getBinInfo} disabled={bin.length === 0 || layout.isLoading}>{T("Next")}</Button>
                    </Col>

                    <Col className="fixed-bottom text-center mb-2">
                        {binLocations.map((binLocation) =>
                            <Chip variant="outlined" className="mx-1"
                                label={<Typography>{binLocation.BIN + " (" + binLocation.CurrentQTY + ")"}</Typography>} />)}
                    </Col>
                </React.Fragment>
            }

            {activeStep === 2 &&
                <React.Fragment>
                    <Col className="d-flex justify-content-center">
                        <OutlinedInput id="Quantity" placeholder={T("ScanQty")} className="mb-2 pr-0" value={quantity} type="number" labelWidth={0}
                            autoFocus={focus.autofocus} onChange={(e) => setQuantity(e.target.value.toUpperCase())} onKeyDown={() => setDeactivateListener(true)} onBlur={() => setDeactivateListener(false)}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton onClick={() => addToQuantity(true)}>
                                        <Add />
                                    </IconButton>

                                    <IconButton onClick={removeFromQuantity}>
                                        <Replay color="disabled" />
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </Col>

                    <Col className="d-flex justify-content-center">
                        <Button onClick={handleBack}>{T("Back")}</Button>
                        <Button variant="contained" color="primary" onClick={submit} disabled={layout.isLoading}>{T("Finish")}</Button>
                    </Col>
                </React.Fragment>
            }
        </EventListener>
    );
}