import Keycloak, { KeycloakInstance } from 'keycloak-js';
import React, { useEffect, useState } from 'react';

import AppUser from '../models/AppUser';

export const UserContext = React.createContext({
    user: {} as AppUser,
    logout: () => { }
})

let keycloakInstance: KeycloakInstance = Keycloak('/keycloak.json');

export const getApiToken = async () => {
    const token = await keycloakInstance.updateToken(30).then(() => {
        return keycloakInstance.token;
    });

    return token;
}

export function UserProvider(props: { children?: React.ReactNode }) {
    const [user, setUser] = useState({} as AppUser);
    const [keycloak] = useState(Keycloak('/keycloak.json'));

    useEffect(() => {
        keycloak.init({}).then(authenticated => {
            if (authenticated)
                loadUserInfo();
            else {
                keycloak.login({ idpHint: "sfcs6h2-provider" });
            }
        });

        keycloakInstance = keycloak;

        // eslint-disable-next-line
    }, []);

    const loadUserInfo = () => {
        keycloak.loadUserInfo().then((userInfo: any) => {
            setUser({
                employeeId: userInfo.employeeId.toUpperCase(),
                name: userInfo.name,
                department: userInfo.department,
                mail: userInfo.email,
                sub: userInfo.sub
            });
        });
    }

    return <UserContext.Provider value={{ user: user, logout: () => keycloak.logout() }}>
        {props.children}
    </UserContext.Provider>
}