import { cs } from './cs';
import { en } from './en';

const langs = new Map<string, {}>()
langs.set("cs", cs);
langs.set("en", en);

const langStoredCode: string = localStorage.getItem("langCode") || 'en';
const lang: any = langs.get(langStoredCode);

export const T = (keyWord: string): string => {
    const translateText = lang.get(keyWord);

    if (translateText) {
        return translateText;
    }

    return "NoTranslate: " + keyWord;
};