import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Col } from 'reactstrap';
import { Card, CardActionArea, CardContent, Typography, List, ListItem, ListItemAvatar, Avatar, Paper, Badge, LinearProgress } from '@material-ui/core';
import { Theme, withStyles, createStyles } from '@material-ui/core/styles';
import { green, red, yellow } from '@material-ui/core/colors';

import { T } from '../utils/translate/translator';
import { LayoutContext } from '../context/LayoutContext';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKeyboard, faShoppingCart, faDollarSign, faDollyFlatbed, faPeopleCarry, faInfoCircle, faGlobeEurope, faDigitalTachograph } from '@fortawesome/free-solid-svg-icons';

declare global {
    interface Navigator {
        getBattery: any,
    }
}

library.add(faKeyboard, faShoppingCart, faDollarSign, faDollyFlatbed, faPeopleCarry, faInfoCircle, faGlobeEurope, faDigitalTachograph)

const StyledBadge = withStyles((theme: Theme) =>
    createStyles({
        badge: { top: 5 }
    }),
)(Badge);

export default function Home() {
    const [batteryLevel, setBatteryLevel] = useState(0);
    const layout = useContext(LayoutContext);

    useEffect(() => {
        layout.setTitle("YAMA material picking");
        batteryListenener();

        // eslint-disable-next-line
    });

    const batteryListenener = () => {
        window.navigator.getBattery().then((battery: any) => {
            setBatteryLevel(battery.level * 100);
            battery.addEventListener('levelchange', () => setBatteryLevel(battery.level * 100), false);
        });
    }

    const BorderLinearProgress = withStyles((theme) => ({
        root: {
            height: 50,
            borderRadius: 5,
        },
        colorPrimary: {
            backgroundColor: 'transparent',
        },
        bar: {
            backgroundColor: getBatteryColor()
        },
    }))(LinearProgress);

    const getBatteryColor = (): string => {
        if (batteryLevel > 70)
            return green[500]
        else if (batteryLevel > 30)
            return yellow[500]
        else
            return red[500]
    }

    return (
        <React.Fragment>
            <Col lg={6} md={8} className="mx-auto">
                <Card className="mt-2">
                    <CardActionArea>
                        <CardContent>
                            <Typography gutterBottom variant="h5">{T("Battery")}</Typography>
                            <BorderLinearProgress variant="determinate" value={batteryLevel} />
                        </CardContent>
                    </CardActionArea>
                </Card>

                <Paper className="my-2">
                    <List>
                        <Link to="/replenishment" className="text-decoration-none">
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <FontAwesomeIcon icon="people-carry" />
                                    </Avatar>
                                </ListItemAvatar>
                                <Typography color="textPrimary">{T("Replenishment")}</Typography>
                            </ListItem>
                        </Link>

                        <Link to="/replenishment2" className="text-decoration-none">
                            <ListItem>
                                <ListItemAvatar>
                                    <StyledBadge badgeContent={2} color="primary">
                                        <Avatar>
                                            <FontAwesomeIcon icon="people-carry" />
                                        </Avatar>
                                    </StyledBadge>
                                </ListItemAvatar>
                                <Typography color="textPrimary">{T("Replenishment")}</Typography>
                            </ListItem>
                        </Link>

                        <Link to="/linkTrolleyAndUsn" className="text-decoration-none">
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <FontAwesomeIcon icon="dolly-flatbed" />
                                    </Avatar>
                                </ListItemAvatar>
                                <Typography color="textPrimary">Link trolley & USN</Typography>
                            </ListItem>
                        </Link>

                        <Link to="/d1" className="text-decoration-none">
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <FontAwesomeIcon icon="shopping-cart" />
                                    </Avatar>
                                </ListItemAvatar>
                                <Typography color="textPrimary">D1 picking</Typography>
                            </ListItem>
                        </Link>

                        <Link to="/d6" className="text-decoration-none">
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <FontAwesomeIcon icon="dollar-sign" color="gold" />
                                    </Avatar>
                                </ListItemAvatar>
                                <Typography color="textPrimary">D6 picking</Typography>
                            </ListItem>
                        </Link>

                        <Link to="/d2" className="text-decoration-none">
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <FontAwesomeIcon icon="keyboard" />
                                    </Avatar>
                                </ListItemAvatar>
                                <Typography color="textPrimary">D2 Picking</Typography>
                            </ListItem>
                        </Link>

                        <Link to="/d3" className="text-decoration-none">
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <FontAwesomeIcon icon="digital-tachograph" />
                                    </Avatar>
                                </ListItemAvatar>
                                <Typography color="textPrimary">D3 Picking</Typography>
                            </ListItem>
                        </Link>

                        <Link to="/trolleyInfo" className="text-decoration-none">
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <FontAwesomeIcon icon="info-circle" />
                                    </Avatar>
                                </ListItemAvatar>
                                <Typography color="textPrimary">Trolley info</Typography>
                            </ListItem>
                        </Link>

                        <Link to="/uni" className="text-decoration-none">
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <FontAwesomeIcon icon="globe-europe" />
                                    </Avatar>
                                </ListItemAvatar>
                                <Typography color="textPrimary">UNI Picking</Typography>
                            </ListItem>
                        </Link>
                    </List>
                </Paper>
            </Col>
        </React.Fragment>
    )
}