import React, { useState, useContext } from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Collapse, ListItemSecondaryAction, Switch, ListItemAvatar, Avatar, ListSubheader } from '@material-ui/core';
import { Translate, ExitToApp, ExpandLess, ExpandMore, Brightness4, Menu, WifiOff, FilterCenterFocus } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { pink } from '@material-ui/core/colors';

import { UserContext } from '../../context/UserContext';
import { AutofocusContext } from '../../context/AutofocusContext';

import { T } from '../../utils/translate/translator';
import { isDesktop } from '../../utils/Helpers';

import { drawerWidth } from './DrawerWidth';

const useStyles = makeStyles((theme) => ({
    drawerPaper: {
        width: drawerWidth,
    },
    pink: {
        color: theme.palette.getContrastText(pink[500]),
        backgroundColor: pink[500],
    },
}));

const langStoredCode: string = localStorage.getItem("langCode") || 'en';

interface RightDrawerProps {
    open: boolean,
    setOpen: (open: boolean) => void,
    theme: string,
    changeAppTheme: (theme: string) => void,
    permanentMenu: boolean,
    setPermanentMenu: (permanent: boolean) => void,
    isOnline: boolean
}

/*Do not modify code of RigthDrawer to keep consistency of wistron projects!*/
export default function RightDrawer(props: RightDrawerProps) {
    const classes = useStyles();
    const [nestedListOpen, setNestedListOpen] = useState(false);
    const user = useContext(UserContext);
    const focus = useContext(AutofocusContext);

    const changeLanguage = (lang: string) => {
        localStorage.setItem("langCode", lang);
        window.location.reload();
    }

    const userInfo = (
        <List>
            <ListItem>
                <ListItemText primary={user.user.name ?? T("Name")} secondary={
                    <span>
                        <span>{user.user.employeeId ?? T("EmployeeId")}</span>
                        <br />
                        <span>{user.user.department ?? T("Department")}</span>
                    </span>
                } />
            </ListItem>

            <ListItem button onClick={() => user.logout()}>
                <ListItemIcon><ExitToApp color="secondary" /></ListItemIcon>
                <ListItemText primary={T('Logout')} />
            </ListItem>
        </List>
    )

    const offline = (
        <List>
            <ListItem>
                <ListItemAvatar>
                    <Avatar className={classes.pink}>
                        <WifiOff />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Offline" />
            </ListItem>
        </List>
    )

    return (
        <Drawer anchor="right" open={props.open} onClose={() => props.setOpen(false)} classes={{ paper: classes.drawerPaper }}
            ModalProps={{ keepMounted: true }}>

            {props.isOnline ? userInfo : offline}

            <List subheader={<ListSubheader>{T("Settings")}</ListSubheader>}>
                <ListItem button onClick={() => props.changeAppTheme(props.theme === "dark" ? "light" : "dark")}>
                    <ListItemIcon>
                        <Brightness4 />
                    </ListItemIcon>
                    <ListItemText primary={T('DarkMode')} />
                    <ListItemSecondaryAction>
                        <Switch edge="end" color="primary" checked={props.theme === "dark"} onChange={() => props.changeAppTheme(props.theme === "dark" ? "light" : "dark")} />
                    </ListItemSecondaryAction>
                </ListItem>

                {isDesktop && <ListItem button onClick={() => props.setPermanentMenu(!props.permanentMenu)}>
                    <ListItemIcon>
                        <Menu />
                    </ListItemIcon>
                    <ListItemText primary={T("DockMenu")} />
                    <ListItemSecondaryAction>
                        <Switch edge="end" color="primary" checked={props.permanentMenu} onChange={() => props.setPermanentMenu(!props.permanentMenu)} />
                    </ListItemSecondaryAction>
                </ListItem>}

                <ListItem button onClick={() => focus.setAutofocus(!focus.autofocus)}>
                    <ListItemIcon>
                        <FilterCenterFocus />
                    </ListItemIcon>
                    <ListItemText primary="Focus" />
                    <ListItemSecondaryAction>
                        <Switch edge="end" color="primary" checked={focus.autofocus} onChange={() => focus.setAutofocus(!focus.autofocus)} />
                    </ListItemSecondaryAction>
                </ListItem>

                <ListItem button onClick={() => setNestedListOpen(!nestedListOpen)} className="py-0">
                    <ListItemIcon>
                        <Translate />
                    </ListItemIcon>
                    <ListItemText primary={T("Language")} secondary={langStoredCode === "en" ? "English" : "Čeština"} />
                    {nestedListOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={nestedListOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem button onClick={() => { changeLanguage('en') }}>
                            <ListItemText primary="English" />
                        </ListItem>
                        <ListItem button onClick={() => { changeLanguage('cs') }}>
                            <ListItemText primary="Čeština" />
                        </ListItem>
                    </List>
                </Collapse>
            </List>
        </Drawer>
    )
}