import React, { useContext } from 'react';
import { Col, Row } from 'reactstrap';
import { Button, TextField, ButtonGroup } from '@material-ui/core';
import { T } from '../../utils/translate/translator';
import { AutofocusContext } from '../../context/AutofocusContext';

import BottomNavigation from '../common/BottomNavigation';
import { InfoModel } from '../../models/TrolleyInfo';

interface ScanPositionProps {
    data: InfoModel[],
    trolleyId: string,
    position: string,
    setPosition: (positionId: string) => void,
    deactivateListener: (active: boolean) => void,
    isFinish: boolean,
    handleNext: () => void,
    handleBack: () => void,
    nextDisabled: boolean,
    hidePosition?: boolean
}

export default function ScanPosition(props: ScanPositionProps) {
    const focus = useContext(AutofocusContext);

    const firstGroup: Array<string> = [props.trolleyId + "01", props.trolleyId + "02", props.trolleyId + "03", props.trolleyId + "04"];
    const secGroup: Array<string> = [props.trolleyId + "05", props.trolleyId + "06", props.trolleyId + "07", props.trolleyId + "08"];

    return (
        <React.Fragment>
            <Col sm={12} lg={6} className="border p-0 my-3 mx-auto">
                <Col className="text-center border-bottom"><b>{T("Trolley")}: </b>{props.trolleyId}</Col>
                <Row className="m-0 d-block">

                    <Row className="m-0">
                        <Col>
                            <TextField placeholder={T("Position")} className="my-1" fullWidth value={props.position}
                                onChange={(event) => props.setPosition(event.target.value.toUpperCase())}
                                onKeyDown={() => props.deactivateListener(true)}
                                onBlur={() => props.deactivateListener(false)} autoFocus={focus.autofocus} hidden={props.hidePosition} />

                            <ButtonGroup fullWidth className="my-1">
                                {firstGroup.map((i, index) => {
                                    const item = props.data.find((k: InfoModel) => k.POSITION === i);

                                    if (item)
                                        return <Button key={index} variant="contained" color="primary" className="word-break">{item.USN}</Button>

                                    return <Button key={index} variant="outlined" style={{ minHeight: '36px' }}></Button>
                                })}
                            </ButtonGroup>

                            <ButtonGroup fullWidth className="mt-1 mb-2">
                                {secGroup.map((i, index) => {
                                    const item = props.data.find((k: InfoModel) => k.POSITION === i);

                                    if (item)
                                        return <Button key={index} variant="contained" color="primary" className="word-break">{item.USN}</Button>

                                    return <Button key={index} variant="outlined" style={{ minHeight: '36px' }}></Button>
                                })}
                            </ButtonGroup>
                        </Col>
                    </Row>

                </Row>
            </Col>

            <BottomNavigation handleBack={props.handleBack} handleNext={props.handleNext} finish={props.isFinish} disableNext={props.nextDisabled} />
        </React.Fragment>
    );
}