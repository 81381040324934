export const en = new Map<string, string>();

//Layout
en.set("Logout", "Logout");
en.set("Language", "Language");
en.set("DarkMode", "Dark mode");
en.set("AreYouSureYouWantToContinue", "Are you sure you want to continue?");
en.set("Photo", "Photo");
en.set("Settings", "Settings");
en.set("DockMenu", "Dock menu");
en.set("Name", "Name");
en.set("EmployeeId", "Employee ID");
en.set("Department", "Department");
en.set("SignIn", "Sign in");
en.set("Home", "Home");
en.set("Battery", "Battery");

en.set("Replenishment", "Replenishment");
en.set("Accessory", "Accessory");
en.set("TrolleyInfo", "Trolley info");

en.set("ScanBarcode", "Scan barcode");
en.set("ScanBin", "Scan BIN");
en.set("ScanCpn", "Scan CPN");
en.set("ScanPN", "Scan P/N");
en.set("ScanQty", "Scan Quantity");
en.set("Back", "Back");
en.set("Next", "Next");
en.set("Finish", "Finish");
en.set("ScanTrolley", "Scan trolley");
en.set("Trolley", "Trolley");
en.set("Position", "Position");
en.set("BinQuantity", "Bin Qty");
en.set("PickQuantity", "Pick Qty");
en.set("Quantity", "Quantity");
en.set("Current", "Current");
en.set("Scanned", "Scanned");

//Notifications
en.set("BinError1/2", "The scanned BIN (");
en.set("BinError2/2", ") is not the requested");
en.set("ScanBinHelperText", "Go to the rack and scan BIN location");
en.set("PositionError1/2", "The scanned position (");
en.set("PositionError2/2", ") is wrong");
en.set("SfcsDeductError", "Error deduct material QTY from SFCS:");
en.set("NoTrolleyOpen", "No trolley open");
en.set("NotTheRightTrolley", "Not the right trolley");