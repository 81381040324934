import React from 'react';
import { SwipeableDrawer, List, Hidden, Drawer, Typography, Badge, Avatar } from '@material-ui/core';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';

import { T } from '../../utils/translate/translator';
import packageJson from '../../../package.json';
import { drawerWidth, miniDrawerWidth } from './DrawerWidth';
import MenuItem from './leftDrawer/MenuItem';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKeyboard, faShoppingCart, faDollarSign, faDollyFlatbed, faPeopleCarry, faInfoCircle, faGlobeEurope, faHome, faDigitalTachograph } from '@fortawesome/free-solid-svg-icons';

library.add(faKeyboard, faShoppingCart, faDollarSign, faDollyFlatbed, faPeopleCarry, faInfoCircle, faGlobeEurope, faHome, faDigitalTachograph)

const StyledBadge = withStyles((theme: Theme) =>
    createStyles({
        badge: {
            right: 10,
            top: 5
        },
    }),
)(Badge);

const useStyles = makeStyles((theme) => ({
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    drawerPaper: {
        width: drawerWidth
    },
    miniDrawerPaper: {
        width: miniDrawerWidth,
        top: '64px',
        overflow: 'hidden'
    }
}));

interface LeftDrawerProps {
    open: boolean,
    setOpen: (open: boolean) => void,
    permanentLeftDrawer: boolean,
    miniVariantLeftDrawer: boolean
}

export default function LeftDrawer(props: LeftDrawerProps) {
    const classes = useStyles();

    const drawer = (
        <List>
            {/*For more menu links, copy this MenuItem tag and insert icon as children component.*/}
            <MenuItem url="/" title={T("Home")} onClick={() => props.setOpen(false)}>
                <Avatar>
                    <FontAwesomeIcon icon="home" />
                </Avatar>
            </MenuItem>

            <MenuItem url="/replenishment" title={T("Replenishment")} onClick={() => props.setOpen(false)}>
                <Avatar>
                    <FontAwesomeIcon icon="people-carry" />
                </Avatar>
            </MenuItem>

            <MenuItem url="/replenishment2" title={T("Replenishment")} onClick={() => props.setOpen(false)}>
                <StyledBadge badgeContent={2} color="primary">
                    <Avatar>
                        <FontAwesomeIcon icon="people-carry" />
                    </Avatar>
                </StyledBadge>
            </MenuItem>

            <MenuItem url="/linkTrolleyAndUsn" title="Link trolley/USN" onClick={() => props.setOpen(false)}>
                <Avatar>
                    <FontAwesomeIcon icon="dolly-flatbed" />
                </Avatar>
            </MenuItem>

            <MenuItem url="/d1" title="D1 picking" onClick={() => props.setOpen(false)}>
                <Avatar>
                    <FontAwesomeIcon icon="shopping-cart" />
                </Avatar>
            </MenuItem>

            <MenuItem url="/d6" title="D6 picking" onClick={() => props.setOpen(false)}>
                <Avatar>
                    <FontAwesomeIcon icon="dollar-sign" color="gold" />
                </Avatar>
            </MenuItem>

            <MenuItem url="/d2" title="D2 picking" onClick={() => props.setOpen(false)}>
                <Avatar>
                    <FontAwesomeIcon icon="keyboard" />
                </Avatar>
            </MenuItem>

            <MenuItem url="/d3" title="D3 picking" onClick={() => props.setOpen(false)}>
                <Avatar>
                    <FontAwesomeIcon icon="digital-tachograph" />
                </Avatar>
            </MenuItem>

            <MenuItem url="/trolleyInfo" title="Trolley info" onClick={() => props.setOpen(false)}>
                <Avatar>
                    <FontAwesomeIcon icon="info-circle" />
                </Avatar>
            </MenuItem>

            <MenuItem url="/uni" title="UNI Picking" onClick={() => props.setOpen(false)}>
                <Avatar>
                    <FontAwesomeIcon icon="globe-europe" />
                </Avatar>
            </MenuItem>
        </List>
    );

    /*Do not modify code below to keep consistency of wistron projects!*/
    const appVersion = (
        <Typography variant="body1" className="text-center my-3"><b>Version: </b>{packageJson.version}</Typography>
    )

    /*Do not modify code below to keep consistency of wistron projects!*/
    return (
        <nav className={props.permanentLeftDrawer ? classes.drawer : ""}>
            {props.permanentLeftDrawer ?
                <React.Fragment>
                    <Hidden smUp implementation="css">
                        <SwipeableDrawer anchor="left" open={props.open} onClose={() => props.setOpen(false)} onOpen={() => props.setOpen(true)}
                            classes={{ paper: classes.drawerPaper }} ModalProps={{ keepMounted: true }}>
                            {appVersion}
                            {drawer}
                        </SwipeableDrawer>
                    </Hidden>

                    <Hidden xsDown implementation="css">
                        <Drawer classes={{ paper: props.miniVariantLeftDrawer ? classes.miniDrawerPaper : classes.drawerPaper }} variant="permanent" open>
                            {!props.miniVariantLeftDrawer && appVersion}
                            {drawer}
                        </Drawer>
                    </Hidden>
                </React.Fragment>
                :
                <SwipeableDrawer anchor="left" open={props.open} onClose={() => props.setOpen(false)} onOpen={() => props.setOpen(true)}
                    classes={{ paper: classes.drawerPaper }} ModalProps={{ keepMounted: true }}>
                    {appVersion}
                    {drawer}
                </SwipeableDrawer>
            }
        </nav>
    )
}