import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

//Contexts
import { UserProvider } from './context/UserContext';
import { LayoutProvider } from './context/LayoutContext';
import { AutofocusProvider } from './context/AutofocusContext';

//Pages
import Home from './pages/Home';
import Replenishment from './pages/Replenishment';
import ReplenishmentV2 from './pages/ReplenishmentV2';
import LinkTrolleyAndUsn from './pages/LinkTrolleyAndUsn';
import D1Picking from './pages/D1Picking';
import D2Picking from './pages/D2Picking';
import D3Picking from './pages/D3Picking';
import D6Picking from './pages/D6Picking';
import TrolleyInfoPage from './pages/TrolleyInfo';
import UniPicking from './pages/UniPicking';

export default function App() {
    return (
        <BrowserRouter>
            <UserProvider>
                <AutofocusProvider>
                    <LayoutProvider>
                        <Switch>
                            <Route exact path='/' component={Home} />
                            <Route exact path='/replenishment' component={Replenishment} />
                            <Route exact path='/replenishment2' component={ReplenishmentV2} />
                            <Route exact path='/d1' component={D1Picking} />
                            <Route exact path='/d2' component={D2Picking} />
                            <Route exact path='/d3' component={D3Picking} />
                            <Route exact path='/d6' component={D6Picking} />
                            <Route exact path='/linkTrolleyAndUsn' component={LinkTrolleyAndUsn} />
                            <Route exact path='/trolleyInfo' component={TrolleyInfoPage} />
                            <Route exact path='/uni' component={UniPicking} />
                        </Switch>
                    </LayoutProvider>
                </AutofocusProvider>
            </UserProvider>
        </BrowserRouter>
    );
}