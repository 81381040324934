import { apiUrl } from "./BaseUrl";
import { vibrate } from "./Helpers";
import { getApiToken } from "../context/UserContext";

//Models and enums
import { ApiResponseStatus } from "../models/enums/ApiResponseStatus";

interface FetchOptions {
    success: (data: any) => void,
    error: (message: string) => void
}

class ApiService {
    private _apiUrl: string = '';

    public Init(apiUrl: string) {
        this._apiUrl = apiUrl;
    }

    public async get(url: string, options: FetchOptions) {

        const initReq: RequestInit = {
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${await getApiToken()}`
            }
        };

        basicFetch(`${this._apiUrl}${url}`, options, initReq);
    }

    //post upraven pro po�adavky aplikace (p�id�n dynamicId)
    public async post(url: string, dynamicId: string, data: any, options: FetchOptions) {

        const initReq: RequestInit = {
            method: 'post',
            credentials: 'include',
            headers: {
                'Dynamic-ID': dynamicId,
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${await getApiToken()}`
            },
            body: JSON.stringify(data)
        };

        basicFetch(`${this._apiUrl}${url}`, options, initReq);
    }

    public async put(url: string, data: any, options: FetchOptions) {

        const initReq: RequestInit = {
            method: 'put',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${await getApiToken()}`
            },
            body: JSON.stringify(data)
        };

        basicFetch(`${this._apiUrl}${url}`, options, initReq);
    }

    public async delete(url: string, data: any, options: FetchOptions) {

        const initReq: RequestInit = {
            method: 'delete',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${await getApiToken()}`
            },
            body: JSON.stringify(data)
        };

        basicFetch(`${this._apiUrl}${url}`, options, initReq);
    }
}

function basicFetch(url: string, options: FetchOptions, initReq: RequestInit) {
    return new Promise(async (resolve, reject) => {
        fetch(url, initReq).then(res => {
            if (res.ok) {
                res.json().then((result) => {
                    if (result && result.Status === ApiResponseStatus.Ok) {
                        options.success(result.Data);
                        resolve(result.Data);
                    } else {
                        //Status from SFCS is ERROR or there is no data
                        vibrate([200, 100, 200]);

                        options.error(result.Message);
                        resolve(result.Message);
                    }
                }).catch(() => {
                    options.success(null);
                    resolve(() => { });
                })
            } else {
                res.json().then((result) => {
                    vibrate([200, 100, 200]);

                    const title = result.title ?? "";
                    const message = result.message ?? "";

                    options.error(title + message + " [" + res.status + "]")
                    resolve(title + message + " [" + res.status + "]");
                }).catch((error) => {
                    vibrate([200, 100, 200]);

                    options.error(error.toString());
                    reject(error.toString());
                });
            }
        }).catch((error) => {
            vibrate([200, 100, 200]);

            options.error(error.toString());
            reject(error.toString());
        });
    });
}

const apiService: ApiService = new ApiService();

apiService.Init(apiUrl);

export { apiService }