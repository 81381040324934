import React from 'react';
import { Link } from 'react-router-dom';
import { Tooltip, ListItem, ListItemIcon, Typography } from '@material-ui/core';

interface MenuItemProps {
    url: string,
    onClick: () => void,
    hidden?: boolean,
    title: string,
    /** Insert material ui icon. */
    children?: React.ReactNode
}

export default function MenuItem(props: MenuItemProps) {

    return (
        <Link to={props.url} className="text-decoration-none" onClick={props.onClick} hidden={props.hidden}>
            <Tooltip title={props.title} enterDelay={1500} placement="right">
                <ListItem button selected={window.location.pathname === props.url}>
                    <ListItemIcon>
                        {props.children}
                    </ListItemIcon>
                    <Typography color="textPrimary">{props.title}</Typography>
                </ListItem>
            </Tooltip>
        </Link>
    )
}