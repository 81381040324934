import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { AppBar, CssBaseline, IconButton, Toolbar, Typography } from '@material-ui/core';
import { Menu, Settings, ArrowBack } from '@material-ui/icons';
import { makeStyles, Theme, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import { isDevelopment } from '../../utils/Helpers';
import { drawerWidth, miniDrawerWidth } from './DrawerWidth';

import LeftDrawer from './LeftDrawer';
import RightDrawer from './RightDrawer';

const themeStoredCode: string = localStorage.getItem("theme") || (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light');
const permanentLeftMenu: string = localStorage.getItem("permanentLeftMenu") || '';
const miniVariantLeftMenu: string = localStorage.getItem("miniVariantLeftMenu") || '';

const darkTheme: Theme = createMuiTheme({ palette: { type: 'dark', /*primary: { main: brown[600] }*/ } })
const lightTheme: Theme = createMuiTheme({ palette: { type: 'light', /*primary: { main: brown[600] }*/ } })

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    rootMiniVersion: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(100%) - ${miniDrawerWidth}px`,
            marginLeft: miniDrawerWidth,
        },
    },
    menuButton: {
        marginRight: theme.spacing(1)
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
    },
    title: {
        flexGrow: 1,
    }
}));

interface LayoutProps {
    children?: React.ReactNode,
    title: string,
    useBackArrow: string,
    setUseBackArrow: (pathToRedirect: string) => void
}

/*Do not modify code of Layout to keep consistency of wistron projects!*/
export default function Layout(props: LayoutProps) {
    const classes = useStyles();
    const [leftDrawerOpen, setLeftDrawerOpen] = useState(false);
    const [rightDrawerOpen, setRightDrawerOpen] = useState(false);
    const [permanentMenu, setPermanentMenu] = useState(Boolean(permanentLeftMenu));
    const [miniVariantMenu, setMiniVariantMenu] = useState(Boolean(miniVariantLeftMenu));
    const [theme, setTheme] = useState(themeStoredCode);
    const [isOnline, setIsOnline] = useState(true);
    const history = useHistory();

    useEffect(() => {
        if (themeStoredCode === "dark")
            changeAppTheme("dark")

        window.addEventListener("online", () => setIsOnline(true));
        window.addEventListener("offline", () => setIsOnline(false));
    }, []);

    useEffect(() => {
        if (!isOnline)
            setRightDrawerOpen(true);

    }, [isOnline]);

    const changeAppTheme = (theme: string) => {
        document.body.style.backgroundColor = "";
        document.body.style.color = "";

        if (theme === "dark") {
            document.body.style.backgroundColor = "#212121";
            document.body.style.color = "#fafafa";
        }

        setTheme(theme);
        localStorage.setItem("theme", theme);
    }

    const changeLeftMenuDock = (permanent: boolean) => {
        setPermanentMenu(permanent);
        localStorage.setItem("permanentLeftMenu", permanent ? "true" : "");
    }

    const handleOnLeftIconClick = () => {
        if (props.useBackArrow) {
            history.push(props.useBackArrow);
            return props.setUseBackArrow("");
        }

        //600 is breakpoint for "sm", necessary for small width, where permanent menu is hidden
        if (permanentMenu && window.innerWidth > 600) {
            if (miniVariantMenu) {
                setMiniVariantMenu(false);
                return localStorage.setItem("miniVariantLeftMenu", "");
            } else {
                setMiniVariantMenu(true);
                return localStorage.setItem("miniVariantLeftMenu", "true");
            }
        } else {
            return setLeftDrawerOpen(true);
        }
    }

    return (
        <MuiThemeProvider theme={theme === "dark" ? darkTheme : lightTheme}>
            <div className={permanentMenu ? miniVariantMenu ? classes.rootMiniVersion : classes.root : ""}>
                <CssBaseline />
                <AppBar position="fixed" className={permanentMenu ? miniVariantMenu ? classes.rootMiniVersion : classes.root : ""}>
                    <Toolbar>
                        <IconButton color="inherit" edge="start" onClick={() => handleOnLeftIconClick()} className={permanentMenu ? classes.menuButton : "mr-2"}>
                            {props.useBackArrow ? <ArrowBack /> : <Menu />}
                        </IconButton>

                        <Typography variant="h6" noWrap className={classes.title}>{isDevelopment && "[QAS]"} {props.title}</Typography>

                        <IconButton color="inherit" edge="end" onClick={() => setRightDrawerOpen(true)}>
                            <Settings />
                        </IconButton>
                    </Toolbar>
                </AppBar>

                <LeftDrawer open={leftDrawerOpen} setOpen={setLeftDrawerOpen} permanentLeftDrawer={permanentMenu} miniVariantLeftDrawer={miniVariantMenu} />
                <RightDrawer open={rightDrawerOpen} setOpen={setRightDrawerOpen} theme={theme} changeAppTheme={changeAppTheme} isOnline={isOnline}
                    permanentMenu={permanentMenu} setPermanentMenu={changeLeftMenuDock} />

                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    {props.children}
                </main>
            </div>
        </MuiThemeProvider>
    );
}