import React, { createContext, useEffect, useState } from 'react';

const autofocusStoredCode: boolean = JSON.parse(localStorage.getItem("autofocusCode") || 'false');

export const AutofocusContext = createContext({
    autofocus: false,
    setAutofocus: (autofocus: boolean) => { }
})

export function AutofocusProvider(props: { children?: React.ReactNode }) {
    const [autofocus, setAutofocus] = useState(autofocusStoredCode);

    useEffect(() => {
        localStorage.setItem("autofocusCode", autofocus ? "true" : "false");

        // eslint-disable-next-line
    }, [autofocus]);

    return <AutofocusContext.Provider value={{ autofocus: autofocus, setAutofocus: setAutofocus }}>
        {props.children}
    </AutofocusContext.Provider>
}