import React, { useContext } from 'react';
import { Col, Row } from 'reactstrap';
import { TextField } from '@material-ui/core';
import { T } from '../../utils/translate/translator';
import { AutofocusContext } from '../../context/AutofocusContext';

import BottomNavigation from './BottomNavigation';
import { PickingInfo } from '../../models/PickingInfo';

interface BinInformationProps {
    data: PickingInfo,
    bin: string,
    setBin: (bin: string) => void,
    deactivateListener: (active: boolean) => void,
    isFinish?: boolean,
    handleNext: () => void,
    handleBack: () => void,
    showPickQty?: boolean,
    children?: React.ReactNode
}

export default function BinInformation(props: BinInformationProps) {
    const focus = useContext(AutofocusContext);

        return (
            <React.Fragment>
                <Col sm={12} lg={6} className="border p-0 my-3 mx-auto">
                    <Col className="text-center border-bottom">{props.children}</Col>
                    <Row className="m-0 d-block">

                        <Row className="m-0">
                            <Col xs={3} className="text-center px-1 border-right">
                                <h2 className="mt-2">{props.data.BIN}</h2>
                                <p className="mb-0 word-break"><b>PN: </b>{props.data.CPN}</p>
                                <p className="mb-0 word-break"><b>{T("BinQuantity")}: </b>{props.data.TOTALQTY}</p>
                                {props.showPickQty && <p className="mb-0 word-break"><b>{T("PickQuantity")}: </b>{props.data.INFO[0].QTY}</p>}
                            </Col>

                            <Col xs={9} className="m-auto text-center">
                                <TextField variant="outlined" label="Scan BIN" placeholder={T("ScanBarcode")} helperText={T("ScanBinHelperText") + ".  (" + props.data.BIN + ")"}
                                    value={props.bin} onChange={(e) => props.setBin(e.target.value.toUpperCase())} autoFocus={focus.autofocus}
                                    onKeyDown={() => props.deactivateListener(true)} onBlur={() => props.deactivateListener(false)} />
                            </Col>
                        </Row>

                    </Row>
                </Col>

                <BottomNavigation handleBack={props.handleBack} handleNext={props.handleNext} finish={props.isFinish} disableNext={!props.bin} />
            </React.Fragment>
        );
}