import React, { useContext } from 'react';
import { Col } from 'reactstrap';
import { TextField } from '@material-ui/core';
import { T } from '../../utils/translate/translator';
import { AutofocusContext } from '../../context/AutofocusContext';

import BottomNavigation from '../common/BottomNavigation';

interface ScanUsnProps {
    usn: string,
    deactivateListener: (active: boolean) => void,
    setUsn: (usn: string) => void,
    handleNext: () => void,
    backEnabled?: boolean,
    handleBack?: () => void | null
}

export default function ScanUsn(props: ScanUsnProps) {
    const focus = useContext(AutofocusContext);

    return (
        <Col className="text-center mt-5">
            <TextField variant="outlined" label="Scan USN" placeholder={T("ScanBarcode")} autoFocus={focus.autofocus}
                value={props.usn} onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.setUsn(e.target.value.toUpperCase())}
                onKeyDown={() => props.deactivateListener(true)} onBlur={() => props.deactivateListener(false)} />

            <BottomNavigation backDisabled={props.backEnabled ? false : true} handleBack={props.handleBack} handleNext={props.handleNext} disableNext={!props.usn} />
        </Col>
    );
}